<template>


<b-row :key="componentKey">
  <div id="overlay" ref="overlay" @click="off()"></div>
  <modal-enviar v-if="openModalEnviar" :opcion-enviar="opcionEnviar" :key="componentKeyEnviar"  :user-id="userId" :token-auth="tokenAuth"  />
      




           <b-col cols="12" sm="12" md="12" lg="3" xl="3" style="margin-bottom:15px"> 




                  <app-collapse
                      accordion
                      type="margin"
                      class="address_card"
              
                    >

                      <app-collapse-item title="Mostrar mi QR de billetera" :isVisible="isVisible"  icon="InfoIcon">

                        <b-row>

                          <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom: 10px;">

                            <b-alert
                                  variant="primary"
                                  show
                                  style="text-align: center;"

                                >
                                  <div class="alert-body">
                                    <span> Comparte tu código QR con tus conocidos y recibe pagos rápidos hacia tu billetera</span>
                                  </div>
                                </b-alert>


                            </b-col>
                            <b-col v-if="hideQR" align="center" cols="12" sm="12" md="12" lg="12" xl="12"> 

                            <b-skeleton  height="130px" width="50%" animation="fade"></b-skeleton>


                            </b-col>

                          <b-col  align="center" cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:10px"> 
                            <vue-qr  style="width: 200px !important;" :callback="getDataURL" :margin="margin" logoBackgroundColor="#fff" backgroundColor="#f0f2f5"  :logoScale="logoScale" :logoMargin="logoMargin" :logoCornerRadius="logoCornerRadius"  :text="scanData" :correctLevel="correctLevel" :size="size"></vue-qr>
                                

                 

                              </b-col>

                              <b-col cols="6" sm="6" md="6" lg="6" xl="6" align="center" >

                                  <b-link @click="descargar()" class="icon_dashboard"> 


                                        <b-avatar
                                        size="40"
                                        variant="light-primary"
                                        style="cursor:pointer;">

                                        <span class="d-flex align-items-center">
                                          <feather-icon
                                          icon="DownloadIcon"
                                          size="21"
                                        />

                                        </span>

                                        </b-avatar>

                             

                                        </b-link>



                                  </b-col>


                                  <b-col  cols="6" sm="6" md="6" lg="6" xl="6" align="center" >

                                  <b-link @click="shared()" class="icon_dashboard"> 


                                        <b-avatar
                                        size="40"
                                        variant="light-primary"
                                        style="cursor:pointer;">

                                        <span class="d-flex align-items-center">
                                          <feather-icon
                                          icon="Share2Icon"
                                          size="21"
                                        />

                                        </span>

                                        </b-avatar>

                                   

                                        </b-link>


                                  </b-col>

                    


                        </b-row>


                  
                      </app-collapse-item>



                    </app-collapse>
                    

            





        </b-col>



       <b-col cols="12" sm="12" md="12" lg="9" xl="9">

      <b-card>

 

          <listado :key="componentKeyListado" :user-id="userId" :token-auth="tokenAuth"
          />


        </b-card>

      
      </b-col>

     
     
      <a v-if="isMobile"  @click="openModal('Ninguno')" class="float" >

        <feather-icon
                        icon="SendIcon"
                        size="30"
                        style="color: white;  margin-top: 16px;margin-right: 5px;"
                        />

        </a>

        <a v-else class="float2"  :class="{'z_index_menu': show_menu}"  >

        <b-dropdown   top  dropdown variant="light" size="sm" @hide="off">
              <template #button-content>

                <div class="float_div" @click="clickOpen"  >
                  <feather-icon
                        icon="SendIcon"
                        size="30"
                        style="color: white;  margin-top: 16px;margin-right: 5px;"
                        />
                          </div>
                  
              
                </template>


        <b-dropdown-item @click="openModal('Email')">
          <feather-icon
                                                            icon="MailIcon"
                                                            size="25"

                                                            class="margin-icon-profile"
                                                            /> Buscar contacto por email

        </b-dropdown-item>

        <b-dropdown-item @click="openModal('Celular')">
       
          <feather-icon
                                                            icon="PhoneCallIcon"
                                                            size="25"

                                                            class="margin-icon-profile"
                                                            /> Buscar contacto por celular

        </b-dropdown-item>

        <b-dropdown-item @click="openModal('Alias')">
          <feather-icon
                                                            icon="UserIcon"
                                                            size="25"

                                                            class="margin-icon-profile"
                                                            /> Buscar contacto por alias


        </b-dropdown-item>



        </b-dropdown>


        </a> 

     
       


</b-row>



</template>

<script>
import {
  VBTooltip,BLink, BButton,VBPopover,BDropdown,BDropdownItem,BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup,BAvatar,BForm,BFormTextarea,BAlert, BRow, BCol, BCard,BSpinner,BSkeleton
} from 'bootstrap-vue'
import PincodeInput from 'vue-pincode-input'
import VueQr from 'vue-qr'
import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';
import Listado from './Listado.vue'
import AppCollapse from '@core/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/app-collapse/AppCollapseItem.vue'

import ModalEnviar from './ModalEnviar.vue'
export default {
  components: {
    BButton,
    ModalEnviar,
    BDropdown,BDropdownItem,
    AppCollapse,AppCollapseItem,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormGroup,
    BFormTextarea,
    BForm,
    BLink,
    BSkeleton,
    BFormInput,
    Listado,
    BAlert,
    
    VueQr,
    BAvatar,
    BSpinner,
    BRow,
    BCol,
    BCard,
    AutoNumericVue,
    PincodeInput
  },
    directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  data() {

    const datos_json = JSON.parse(this.$userGlobal);

      let scanData="";


      if(datos_json.alias === ""){

        if(datos_json.phone === ""){
          scanData= datos_json.email+";interbilletera";
        }else{
          scanData= datos_json.phone+";interbilletera";
        }


      }else{
        scanData= datos_json.alias+";interbilletera";
      }


    let isVisible=true;

if(this.$isMobile){
  isVisible=false;
}



    return {
      userId:datos_json.userId,
      displayName_user:datos_json.displayName,
       photo_user:datos_json.photoUser,
      tokenAuth:this.$tokenGlobal,
      size:800,
      show_menu:false,
      isMobile:this.$isMobile,
      isVisible:isVisible,
       results: null,
      maxChar: 75,
      logoScale:0.3,
            logoMargin: 1,
            logoCornerRadius:100,
      margin:50,
      isDisabled:false,
      inputCelular:false,
      inputEmail:false,
      inputAlias:false,
      activarCampo:false,
      hasDescription:false,
      mount:0,
            componentKey: 100,
      json_opciones:{},
      correctLevel:3,
        componentKeyListado:200,
        dataUrl:"",
       error:"",
       showFormPay:false,
       successPay:false,
       title:"Pagos rápidos con QR",
       scanData:scanData,
       saldoDisponible: 0,
       displayName: "",
       description:"",
       photo: "",
       date:"",
        dataScan: "",
        hideQR:true,
        isPhoneValid:false,
        pin:"",
        opcionEnviar:"",
        openModalEnviar:false,
        componentKeyEnviar:300

    
    }
  },
  computed: {



  },
  watch: {

       results(newValue) {

        this.isPhoneValid= newValue.isValid;
  
    },

  },
  activated() {

this.$eventBus.$emit('reiniciarMenu1')
 this.$eventBus.$emit('reiniciarMenu2')


},
  mounted() {

    this.$eventBus.$on('reiniciarEnviar', () => {
                    this.componentKeyEnviar += 1
                    this.openModalEnviar = false;
                
                    });


   this.json_opciones={allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",   minimumValue: 0, maximumValue:1000000, modifyValueOnWheel: false };



     
    document.title = 'EQCoop - Transferencias interbilletera';

         this.$eventBus.$on('reiniciarListadoInterbilletera', () => {
      this.componentKeyListado += 1
    });

       this.$eventBus.$on('reiniciarTransferencias', () => {
      this.componentKey += 1
    })



  },
  methods: {

    openModal(opcion){

      this.opcionEnviar=opcion;

        this.openModalEnviar = true;

    },

    
    clickOpen(){

      this.show_menu=true;
      this.$refs.overlay.style.display="block";

      },

      off(){
      this.show_menu=false;
      this.$refs.overlay.style.display="none";
      },



    exit(){

          this.$refs.modalScan.hide();

    },
    onCopy() {
          this.$toast.success('Texto copiado', {
            position: 'top-right',
            timeout: 1500,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        },
        onError() {
          this.$toast.error('Falló en copiar el texto', {
            position: 'top-right',
            timeout: 1500,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        },  



    getDataURL(dataUrl,id){

      this.dataUrl= dataUrl;
      this.hideQR=false;

    },
  

    descargar(){

      var link = document.createElement("a");
      link.download = "Mi código QR";
      link.href =this.dataUrl;
      link.click();

   
        

    },

    shared(){


    
      fetch(this.dataUrl).then(function (response) {
        return response.blob()
      }).then(function (blob) {
        let file = new File([blob], "QR.jpg", {type: 'image/jpeg'});
        let filesArray = [file];
        if (navigator.canShare && navigator.canShare({files: filesArray})) {
          navigator.share({
            files: filesArray
          }).then(() => console.log('Successful share'))
            .catch((error) =>   this.$toast.error("Ocurrió un error inesperado", {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            }));
        }else{

            this.$toast.error("Lo sentimos, tu navegador no es compatible para compartir este contenido", {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })

        }
      });


    

    }

   
  }
}
</script>

<style lang="scss">

.qrcode__image2 {
    height: 13%;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 50px;
}


</style>
