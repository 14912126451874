<template>

  <div>
    <vue-bottom-dialog v-if="dialog" :indentColor="indentColor" :topRadius="radius" v-model="dialog" :height="225"  :overlayColor="overlay">
  
         <b-row>   
          
          <b-col cols="12" sm="12" md="12" lg="12" xl="12"> 

          <h4 style="margin: 0; " class="font-weight-bolder">
            Opciones de transferencia de saldo
          
          </h4>

        </b-col>
        <b-col cols="12" sm="12" md="12" lg="12" xl="12"> <hr></b-col>


  <div class="opciones_cripto">
              <label for="Email"> 
  
                <feather-icon
              icon="MailIcon"
              size="20"
              style="margin-right: 10px"
              />Usando un email
  
              </label>
            <input id="Email"  type="radio" @change="onChange($event)"  value="Email" name="radio" />
        </div>
  
      
        <div class="opciones_cripto">
         <label for="Celular"> 
  
          <feather-icon
              icon="PhoneCallIcon"
              size="20"
              style="margin-right: 10px"
              />Usando un número celular
  
         </label>
        <input id="Celular"  type="radio" @change="onChange($event)"  value="Celular" name="radio" />
        </div>
      
        <div class="opciones_cripto">
         <label for="Alias"> 
  
          <feather-icon
              icon="UserIcon"
              size="20"
              style="margin-right: 10px"
              />Usando un alias
  
         </label>
        <input id="Alias"  type="radio" @change="onChange($event)"  value="Alias" name="radio" />
        </div>

      </b-row>  
  
  
  
  </vue-bottom-dialog>
  
  
         <b-modal
        ref="modalSend"
        centered
        hide-footer
              @hidden="closeModal"
              size="sm"
        :title="title"
      >
        <div class="d-block text-center">
  
 
               <b-row v-if="showFormTransferencias">

                

                <b-col  cols="12" sm="12" md="12" lg="12" xl="12" style="padding: 0px;">
                       <b-form autocomplete="off"
                               @submit.prevent="onSubmitSearchManual">

                       <b-row>

               

                             <b-col v-if="isCelular" cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:10px"> 

                                <MazPhoneNumberInput
                                    v-model="dataScan"
                                    type="tel"
                                    default-country-code="EC"
                                    no-flags
                                    no-example
                                    :readonly="activarCampo"
                                    required
                                    show-code-on-list
                                    size="md"
                                    :translations="{
                                      countrySelectorLabel: 'Codigo',
                                      countrySelectorError: 'Seleccione un pais',
                                      phoneNumberLabel: 'Número celular',
                                    }"
                                    @update="results = $event"
                                  />

                             </b-col>

                              <b-col v-if="isEmail" cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:10px"> 

                               <b-input-group>
                                    <b-input-group-prepend is-text>
                                       <feather-icon
                                                    icon="MailIcon"
                                                    size="15"

                                                    class="margin-icon-profile icon_opciones"
                                                    />
                                    </b-input-group-prepend>
                                    <b-form-input
                                      v-model="dataScan"
                                      :readonly="activarCampo"
                                      type="email"
                                      required
                                      placeholder="Ingrese un email"
                                    />
                                  </b-input-group>

                     
                             </b-col>

                              <b-col v-if="isAlias" cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:10px"> 

                                <b-input-group>
                                    <b-input-group-prepend is-text>
                                      @
                                    </b-input-group-prepend>
                                    <b-form-input
                                      v-model="dataScan"
                                      :readonly="activarCampo"
                                      maxlength="20"
                                      type="text"
                                      required
                                      placeholder="Ingrese un alias"
                                    />
                                  </b-input-group>

                     
                             </b-col>

      

                              <b-col cols="12" sm="12" md="12" lg="12" xl="12"> 

                                 <b-button
                                 id="btn_search"
                                  variant="primary"
                                  type="submit"
                                  block
                                  :disabled="activarCampo"
                                >

              
                                       Buscar contacto
                                </b-button>


                              </b-col>
                       

                       </b-row>
                       </b-form>
                       
                       </b-col>
  
                     
  
  
               </b-row>

               <b-row v-if="showFormPay">

                      <b-col
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >

                        <b-alert
                          variant="secondary"
                          show
                          style="margin-bottom: 10px !important;"
                        >
                          <div class="alert-body">
                            
                                <p class="saldoDisponible"><span style="font-size:25px">$</span>{{saldoDisponible}}<small class="currency_cripto">USD</small></p>
                              <p style="font-size: 12px;    margin: 0;">Saldo disponible para transferir</p>


                          
                          </div>
                        </b-alert>


                        </b-col>

                    


                          <b-col
                          cols="5"
                        sm="5"
                        md="5"
                        lg="5"
                        xl="5"
                      >

                        <div class="profile-image p-0">
                            <b-avatar
                              size="50"
                              variant="light"
                              :src="photo_user"
                            />
                          </div>


                      <h4 :title="displayName_user"  class="font-weight-bolder userTransfers">
                          {{ displayName_user }}
                        </h4>


                        <h6 :title="scanData" class="text-muted" style="  cursor: default;  margin: 0;font-size:10px; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                          {{ scanData }}
                        </h6>

                      </b-col>

                          <b-col
                          cols="2"
                        sm="2"
                        md="2"
                        lg="2"
                        xl="2"
                        style="display: flex;"
                      >

                      <svg width="30" height="30"  class="flecha" viewBox="-32 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9.0L441 239c9.4 9.4 9.4 24.6.0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9.0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3.0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"/></svg>

                      </b-col>

                      <b-col
                          cols="5"
                        sm="5"
                        md="5"
                        lg="5"
                        xl="5"
                      >

                        <div class="profile-image p-0">
                            <b-avatar
                              size="50"
                              variant="light"
                              :src="photo"
                            />
                          </div>


                      <h4 :title="displayName" class="font-weight-bolder userTransfers">
                          {{ displayName }}
                        </h4>


                        <h6 :title="dataScan" class="text-muted" style=" cursor: default;   margin: 0;font-size:10px; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                          {{ dataScan }}
                        </h6>

                      </b-col>

                      
                      <b-form
                      autocomplete="off"
                      @submit.prevent="onSubmit"
                      >
                      <b-row>



                      <b-col
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >

                      <hr>

                                  <p
                          class="textLabel"
                          style="text-align: center;margin-top: 0px;margin-bottom: 5px;"
                        >
                          Monto a transferir a tu contacto (*):</p>

                          <AutoNumericVue
                          v-model="mount"
                          required
                          type="text" inputmode="decimal"
                          :readonly="isDisabled"
                          :autofocus="false"
                          @blur="onBlurMount"
                          class="autonumeric_input"
                          :options="json_opciones"
                          :placeholder="'$0.00'"
                      ></AutoNumericVue>

                      </b-col>

                      <b-col
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      >
                        <p class="textLabel" style="text-align: left;margin-top: 0px;margin-bottom: 5px;">
                          Descripción de la transferencia (Opcional):</p>

                            <b-form-textarea
                          v-model="description"
                          placeholder="Ingrese una descripción corta de la transferencia (Opcional)"
                          rows="3"
                          no-resize
                          :readonly="isDisabled"
                          :state="description.length <= maxChar"
                          class="char-textarea"
                          :class="description.length >= maxChar ? 'text-danger' : ''"
                        />
                        <small
                          class="textarea-counter-value float-left"
                          :class="description.length >= maxChar ? 'bg-danger' : ''"
                        >
                          <span class="char-count">{{ description.length }}</span> / {{ maxChar }}
                        </small>

                      </b-col>

                      <b-col
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >
                        <p
                          class="textLabel"
                          style="text-align: center;margin-bottom: 10px;"
                        >
                          Ingrese su código PIN (*): <feather-icon
                                            icon="HelpCircleIcon"
                                            size="16"
                                              v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                            title="Código PIN de seguridad"
                                            style="margin-right: 5px;cursor:pointer;"
                                            /> </p>

                        <PincodeInput
                          v-model="pin"
                          placeholder="-"
                          :length="6"
                          :autofocus="false"
                            :disabled="isDisabled"
                          :secure="true"
                          required
                        />

                      </b-col>


                      <b-col
                      cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >

                        <b-button
                          id="btn_transferir"
                          ref="btn_transferir"
                          type="submit"
                          variant="primary"
                          block
                          :disabled="isDisabled"
                        >

                          Transferir dinero

                        </b-button>

                      </b-col>

      


                      </b-row>

                      </b-form>


                      </b-row>


                
  
          </div>
  
          </b-modal>
  
  
        </div>
  
  
  </template>
  
  <script>
  import {
    VBTooltip, BButton,VBPopover,BForm,BAlert, BRow, BCol, BCard,BSpinner,BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup,BAvatar,BFormTextarea
  } from 'bootstrap-vue'
  import PincodeInput from 'vue-pincode-input'
  import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';

  export default {
    components: {
      BButton,
      BForm,
      BAlert,
      BAvatar,
      BFormTextarea,
      BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup,
      BSpinner,
      BRow,
      BCol,
      BCard,
      AutoNumericVue,
      PincodeInput
    },
      directives: {
      'b-popover': VBPopover,
      'b-tooltip': VBTooltip,
    },
    props: ['userId','tokenAuth', 'opcionEnviar'],
    data() {
  
      const datos_json = JSON.parse(this.$userGlobal);

      let scanData= datos_json.phone;

if(datos_json.phone === ""){

  scanData= datos_json.email;

}

      return {
          pin:"",
          isDisabled:false,
          displayName_user:datos_json.displayName,
       photo_user:datos_json.photoUser,
          mount:"",
          source:"",
          maxChar: 75,
          scanData:scanData,
          title:"",
          isEmail:false,
          isCelular:false,
          isAlias:false,
          activarCampo:false,
          results: null,
          isPhoneValid:false,
          showFormPay:false,
          dataScan:"",
          showFormTransferencias:true,
          json_opciones:{},
          isMobile:this.$isMobile,
          dialog:false,
          color: "#242526",
        overlay:"#000000e6",
        radius:"15px",
         indentColor:"#b0b3b8",
         displayName:"",
         photo:"",
         date:"",
         description:"",
         saldoDisponible:0,
         body: document.getElementsByTagName('body')[0],
  
  
      
      }
    },
    computed: {
  
  
  
    },
    watch: {
  
  
      dialog(newValue) {
        if(newValue === false && this.source === ""){
  
          this.$eventBus.$emit('reiniciarEnviar')
          
  
        }

    
        if(newValue){
          this.body.classList.add("overflow-hidden");
        }else{
          this.body.classList.remove("overflow-hidden");
        }
  
      },   results(newValue) {

          this.isPhoneValid= newValue.isValid;

          },
  
   
  
    },
    mounted() {
  
  
  
        
  
       this.json_opciones={allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",   minimumValue: 0, maximumValue:1000000, modifyValueOnWheel: false };
  
  

  this.dataScan="";
  this.activarCampo=false;
  
  if(this.isMobile){
  
    this.dialog=true;
  
  }else{
    this.source = this.opcionEnviar;
  
    if(this.source === "Email"){
      this.title="Transferir saldo por email";
      this.isEmail=true;
      this.isCelular=false;
      this.isAlias=false;

      this.$refs.modalSend.show();
    
  
    }else{
  
      if(this.source === "Celular"){
      this.title="Transferir saldo por celular";
      this.isEmail=false;
      this.isCelular=true;
      this.isAlias=false;
      this.$refs.modalSend.show();
  
    }else{

      if(this.source === "Alias"){
            this.title="Transferir saldo por alias";
            this.isEmail=false;
            this.isCelular=false;
            this.isAlias=true;
            this.$refs.modalSend.show();
        
          }
  
      
      }
    }
      
    }
  
  
  
    },
    methods: {
            closeModal(){
    
         this.$eventBus.$emit('reiniciarEnviar')
  
      },
      onSubmitSearchManual(event) {
      event.preventDefault();

      if(this.isCelular){

        if(this.isPhoneValid){

           this.activarCampo=true;

      
      document.getElementById('btn_search').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Buscando'

       const data_json = { userId: this.userId, dataScan: this.dataScan }
   

  
      this.$https.post('/interbilletera/searchUserQR/', { tokenAuth: this.tokenAuth, data: data_json}).then(response => {

         this.activarCampo=false;
          
              document.getElementById('btn_search').innerHTML = 'Buscar contacto'

                        
     
                  if (response.data.code == 200) {

         
                      this.showFormTransferencias=false;
                  

       

                     this.title= "Transferir saldo interbilleteras";
                     this.date="";

                     this.saldoDisponible= response.data.balance;
                      this.displayName= response.data.displayName;
                       this.photo= response.data.photo;
                      

                        this.isDisabled=false;
                        this.pin="";
                        this.mount="";
                        this.description="";

                    this.showFormPay=true;
             
          
                    
                

         
                  } else {

                    this.showFormPay=false;
              
              
                    

               
                        this.$toast.error(response.data.message, {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: true,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })


                    if (response.data.code == 401) {
                      
                      localStorage.removeItem('userData')



                      
                      

                      

                      
                      

                      // Redirect to login page
                      this.$router.push({ name: 'auth-login' })
                    }
                  }
                }).catch(error => {

                    this.showFormPay=false;
              
                    

                  
                   
                    this.$toast.error("Ocurrió un error inesperado", {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: true,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })
                  
                })

        }else{

        

            this.$toast.error("Ingrese un número celular válido para el país seleccionado.", {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: true,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })


        }

      }else{

         this.activarCampo=true;

      
      document.getElementById('btn_search').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Buscando'

       const data_json = { userId: this.userId, dataScan: this.dataScan }
   
  
      this.$https.post('/interbilletera/searchUserQR/', { tokenAuth: this.tokenAuth, data: data_json}).then(response => {

         this.activarCampo=false;
          
              document.getElementById('btn_search').innerHTML = 'Buscar contacto'

                        
     
                  if (response.data.code == 200) {

                
                      this.showFormTransferencias=false;
                   

                 

                      this.title= "Transferir saldo interbilleteras";
                     this.date="";

                     this.saldoDisponible= response.data.balance;
                      this.displayName= response.data.displayName;
                       this.photo= response.data.photo;
                      

                        this.isDisabled=false;
                        this.pin="";
                        this.mount="";
                        this.description="";

                    this.showFormPay=true;
              
                    
                    
              

         
                  } else {

                    this.showFormPay=false;
               
                  
                    

               
                        this.$toast.error(response.data.message, {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: true,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })


                    if (response.data.code == 401) {
                      
                      localStorage.removeItem('userData')



                      
                      

                      

                      
                      

                      // Redirect to login page
                      this.$router.push({ name: 'auth-login' })
                    }
                  }
                }).catch(error => {

                    this.showFormPay=false;
        
                 

                  
                   
                    this.$toast.error("Ocurrió un error inesperado", {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: true,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })
                  
                })

      }

     


    },
  
  
      onChange(event) {
  
        this.dialog=false;
  
   
  
       
        this.source = event.target.value;
       
  
        if(this.source === "Email"){
              this.title="Transferir saldo por email";
              this.isEmail=true;
              this.isCelular=false;
              this.isAlias=false;

              this.$refs.modalSend.show();
            
          
            }else{
          
              if(this.source === "Celular"){
              this.title="Transferir saldo por celular";
              this.isEmail=false;
              this.isCelular=true;
              this.isAlias=false;
              this.$refs.modalSend.show();
          
            }else{

              if(this.source === "Alias"){
                    this.title="Transferir saldo por alias";
                    this.isEmail=false;
                    this.isCelular=false;
                    this.isAlias=true;
                    this.$refs.modalSend.show();
                
                  }
          
              
              }
            }
  
        
  
  
  
      },
       onSubmit(event) {

        
            event.preventDefault()

            if(Number(this.description.length) > Number(this.maxChar)){

              this.description="";

                    this.$toast.error("El texto de la descripción sobrepasa el límite permitido de 75 caracteres", {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })

            }else{

              this.mount= Number(this.mount).toFixed(2);



            const userId_json = {
              userIdWhoPay: this.userId, pin: this.pin, mount: this.mount, description: this.description, dataScan:this.dataScan, origen: "interbilletera"
            }



            const user_string = JSON.stringify(userId_json)

            const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

            this.isDisabled = true

            document.getElementById('btn_transferir').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Transferiendo'

            this.$https.post('/interbilletera/payWithQR/', { tokenAuth: this.tokenAuth, data: dataEncripted}).then(response => {
            this.isDisabled = false



              document.getElementById('btn_transferir').innerHTML = 'Transferir';
              this.$eventBus.$emit('reiniciarListadoInterbilletera');

              if (response.data.code == 200) {

                this.date= response.data.date;
                this.title="Transacción exitosa";

                this.showFormPay=false;

        

                this.$toast.success(response.data.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                });

                this.$eventBus.$emit('reiniciarEnviar');
                this.$eventBus.$emit('reiniciarEnviar2');
                this.$eventBus.$emit('reiniciarTransaccionesHome');


              } else {
                this.isDisabled = false

                this.$toast.error(response.data.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })

                if (response.data.code == 401) {
                  
                  localStorage.removeItem('userData')



                  
                  

                

                  
                  

                  // Redirect to login page
                  this.$router.push({ name: 'auth-login' })
                } else if (response.data.code == 503) {
                  this.pin = ''
                }else if(response.data.code == 501){
                  this.$swal({
                      title: 'Tu perfil debe estar verificado para realizar esta acción',
                      text: `¿Deseas verificar tu cuenta ahora?`,
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Verificar cuenta',
                      cancelButtonText: 'No',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-secondary ml-1',
                      },
                      buttonsStyling: false,
                    }).then(result => {
                      if (result.value) {

                        this.$router.push({ name: 'perfil', hash: '#verificacion' })

                      }
                    })
                }
              }
            }).catch(error => {
              this.$toast.error("Ocurrió un error inesperado", {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })
            })

            }
        
  
      
      },
      onBlurMount(){
        if(this.mount == null || this.mount == "null" || this.mount ==""){



            }else{

                  if(Number(this.mount) > Number(this.saldoDisponible)){
                  

                      this.mount="";

                      this.$toast.error("La cantidad a enviar es mayor a su saldo disponible", {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                          })

              


                  }




            }
  
      },
  
      
  
     
    }
  }
  </script>
  
  <style lang="scss">

  
  </style>
  